import React from "react"
import { Link } from "gatsby"

import { css } from "@emotion/core"

import Layout from "../components/layout"
import Services from "../components/services"
import Contact from "../components/contact"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="Home" />
    <h1>Welcome!</h1>
    <div css={css`
        padding: calc(1rem / 2);
      `}
    >
      
      <p>
        Hi my name is Reino Muhl and I'm a Fullstack JavaScript software developer, with experience in React, Node.js, Python and more.
      </p>
      <p>
        I love solving problems and my 8+ years in enterprise software consulting have left me with a customer first 
        design and development approach. Take a peak below to see what <Link to="/services/"><strong>Services</strong></Link> I offer.
      </p>
      <p>
        If you'd like to get in touch, reach out on any of the methods below or head over to my <Link to="/contact/"><strong>Contact</strong></Link> page
        to send me a message.
      </p>
      <div css={ css`
          scale: 80%;
          margin-bottom: 1.5rem;
        `}
      >
        <Contact />
      </div>
      <p>
        To get to know me a bit better, stop by my <Link to="/about/"><strong>About</strong></Link> page.
      </p>
    </div>
    <h2 css={ css`
          font-size: 2rem;
        `}
    >
      Services
    </h2>
    <Services />
  </Layout>
)